import { InteractionStatus } from "@azure/msal-browser";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { acquireTokenRedirectWrapped, b2cPolicies } from "../authConfig";
import { Link } from "@tanstack/react-router";
import { UserIcon } from "lucide-react";

export const EditProfileLink = () => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress, instance } = useMsal();

  const handleProfileEdit = () => {
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      acquireTokenRedirectWrapped(instance, b2cPolicies.authorities.editProfile);
    }
  };

  return (
    <Link className="flex items-center gap-2" onClick={handleProfileEdit}>
      <UserIcon className="h-4 w-4" />
      <span>Edit Profile</span>
    </Link>
  );
};
