import { PageTitle } from "@/components/PageTitle";
import { Button } from "@/components/ui/button";
import { recipeDraftsQueryOptions } from "@/models/categories.queryOptions";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, Link } from "@tanstack/react-router";
import { useMemo } from "react";

export const Route = createFileRoute("/recipe-drafts/$recipeDraftId")({
  loader: ({ context }) => {
    return context.queryClient.ensureQueryData(recipeDraftsQueryOptions);
  },
  component: RecipeDraftPage,
});

function RecipeDraftPage() {
  const { recipeDraftId } = Route.useParams();

  const { data: recipeDrafts } = useSuspenseQuery(recipeDraftsQueryOptions);

  const recipeDraft = useMemo(() => {
    return recipeDrafts?.find(
      (recipeDraft) => recipeDraft.recipeDraftId === +recipeDraftId
    );
  }, [recipeDrafts, recipeDraftId]);

  return (
    <div className="container mt-1 md:mt-3 px-2 pb-3">
      <div className="flex flex-row gap-x-2 justify-between">
        <PageTitle>{recipeDraft?.sourceUrl} - Opskriftskladde</PageTitle>
        <Button variant={"outline"} size={"sm"}>
          <Link to="/recipe-drafts">Tilbage</Link>
        </Button>
      </div>

      <pre>{recipeDraft?.responseJson}</pre>
    </div>
  );
}
