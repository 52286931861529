import { useEffect } from 'react';
import { BrowserUtils } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';

import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/logout')({
  component: Logout,
})

export function Logout() {
  const { instance } = useMsal();

  useEffect(() => {
    instance.logoutRedirect({
      account: instance.getActiveAccount(),
      onRedirectNavigate: () => !BrowserUtils.isInIframe(),
    });
  }, [instance]);

  return <div>Logout</div>;
}
