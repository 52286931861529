import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './index.css';
import * as Sentry from "@sentry/react";

import { RouterProvider, createRouter } from '@tanstack/react-router';

Sentry.init({
  dsn: "https://540e996ba6b4ec1b01cb17744206f9bf@o4507870636670976.ingest.de.sentry.io/4507870639882320",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/hacobo-api-2024-1\.azurewebsites\.net\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


import { routeTree } from './routeTree.gen';


// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

const router = createRouter({
  routeTree,
  context: { queryClient },
  defaultPreload: 'intent',
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
});


import { PublicClientApplication, EventType, AuthenticationResult } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { MsalProvider } from '@azure/msal-react';
import { ThemeProvider } from './themeProvider';
import { dayjsSetup } from './dayjsConfig';
import ReloadPrompt from './components/ReloadPrompt';
import TopLoadingBar from './components/TopLoadingBar';

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (
      event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS
    ) {
      const authenticationResult = event.payload as AuthenticationResult;
      if (authenticationResult.account) {
        const account = authenticationResult.account;
        msalInstance.setActiveAccount(account);
      } else {
        console.error('event is not of expected type AuthenticationResult', event);
      }
    }
  });

  const rootElement = document.getElementById('root')!;
  if (!rootElement.innerHTML) {
    dayjsSetup();

    const root = ReactDOM.createRoot(rootElement);
    root.render(
      <React.StrictMode>
        <MsalProvider instance={msalInstance}>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
              <RouterProvider router={router} />
              <TopLoadingBar />
              <ReloadPrompt />
            </ThemeProvider>
          </QueryClientProvider>
        </MsalProvider>
      </React.StrictMode>
    );
  }
});
