import { IPublicClientApplication, LogLevel } from "@azure/msal-browser";

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
 export const b2cPolicies = {
    names: {
        signUpSignIn: "B2C_1_signupsignin1",
        editProfile: "B2C_1_profileediting1",
        resetPassword: "B2C_1_reset_password"
    },
    authorities: {
        signUpSignIn: {
            authority: "https://hacobo.b2clogin.com/hacobo.onmicrosoft.com/B2C_1_signupsignin1"
        },
        editProfile: {
            authority: "https://hacobo.b2clogin.com/hacobo.onmicrosoft.com/B2C_1_profileediting1"
        },
        resetPassword: {
            authority: "https://hacobo.b2clogin.com/hacobo.onmicrosoft.com/B2C_1_reset_password"
        }
    },
    authorityDomain: "hacobo.b2clogin.com"
}

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: "78c872b6-7b81-44e6-9444-55ceb811e876",
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: isIE || isEdge || isFirefox
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        //console.info(message);
                        return;
                    case LogLevel.Verbose:
                        //console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
};

// Scopes you add here will be prompted for consent during login
export const loginRequest = {
    scopes: [
        'https://hacobo.onmicrosoft.com/hacobo-2023.1-api/admin',
    ]
};

/**
 * Enter here the coordinates of your web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
export const apiConfig = {
    scopes: ['https://hacobo.onmicrosoft.com/hacobo-2023.1-api/admin'],
    uri: 'https://hacobo-api-2023-1.azurewebsites.net/api/categories'
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const acquireTokenRedirectWrapped = (instance:  IPublicClientApplication, request: any) => {
    instance.acquireTokenRedirect(request);
}