export const enum LineTypes {
    Bold = 'bold',
    Italics = 'italics',
    Text = 'text',
    Ingredient = 'ingredient',
}

export interface IRecipeLine {
    index: number;
}

export class BoldLine implements IRecipeLine {
    public readonly type = LineTypes.Bold
    constructor(public index: number, public value: string) { }
}

export class ItalicsLine implements IRecipeLine {
    public readonly type = LineTypes.Italics
    constructor(public index: number, public value: string) { }
}

export class TextLine implements IRecipeLine {
    public readonly type = LineTypes.Text
    constructor(public index: number, public value: string) { }
}

export class IngredientLine implements IRecipeLine {
    public readonly type = LineTypes.Ingredient
    constructor(public index: number, public amount: string | undefined, public product: string) { }
}

export type RecipeLineType = BoldLine | ItalicsLine | TextLine | IngredientLine;

export const getStructuredRecipe = (recipe: string): RecipeLineType[] => {
    const recipeLines: RecipeLineType[] = [];

    if (recipe == null) {
        return [];
    } else {
        const lines = recipe.split(/\r\n|\r|\n/g);

        for (let i = 0; i < lines.length; i++) {
            const parts = lines[i].split('|');
            if (parts.length == 2) {
                recipeLines.push(new IngredientLine(i, parts[0], parts[1]));
            } else {
                if (lines[i].startsWith('*')) {
                    recipeLines.push(new ItalicsLine(i, lines[i].substring(1)));
                }
                else if (lines[i].startsWith('_')) {
                    recipeLines.push(new BoldLine(i, lines[i].substring(1)));
                } else {
                    recipeLines.push(new TextLine(i, lines[i]));
                }
            }
        }
        return recipeLines;
    }
};