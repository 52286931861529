import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Recipe } from "./category";
import { axiosInstance, getHeadersWithAuthentication } from "./axiosInstance";
import { ResponseType } from 'axios'

import dayjs from 'dayjs';

export const fetchRecipes = async (): Promise<Recipe[]> => {
  const headers = await getHeadersWithAuthentication(undefined, { requireAccount: false });
  const response = await axiosInstance
    .get(`/recipes`, {
      headers: headers,
      responseType: 'json' as ResponseType,
    });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return response.data.map((recipe: any) => {
    return {
      ...recipe,
      createdAt: dayjs(recipe.createdAt),
    } as Recipe;
  });
};

//-- Add a recipe mutation
export const useAddRecipeMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (recipe: Recipe) => {
      const headers = await getHeadersWithAuthentication(undefined, { requireAccount: true });
      const response = await axiosInstance.post(`/recipes`, recipe, {
        headers: headers,
      })
      .catch((error) => {
        throw new Error('Failed to add recipe: ' + error);
      });

      return response.data as Recipe;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['recipes'] })
    },
    onError: async (error) => {
      console.log("error:", error)
    }
  })
}


export const useUpdateRecipeMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (recipe: Recipe) => {
      const headers = await getHeadersWithAuthentication(undefined, { requireAccount: true });
      const response = await axiosInstance.put(`recipes/${recipe.recipeId}`, recipe, {
        headers: headers,
      })
      .catch((error) => {
        throw new Error('Failed to update recipe: ' + error);
      });

      return response.data as Recipe;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['recipes'] })
    },
    onSettled: async (data, error) => {
      if (error) {
        console.log("error:", error)
      } else {
        console.log("data:", data)
      }
    }
  })
}

export const useDeleteRecipeMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (recipeId: number) => {
      const headers = await getHeadersWithAuthentication(undefined, { requireAccount: true });
      const response = await axiosInstance.delete(`recipes/${recipeId}`, {
        headers: headers,
      })
      .catch((error) => {
        throw new Error('Failed to delete recipe: ' + error);
      });

      return response.data;
    },
    onSettled: async (data, error) => {
      if (error) {
        console.log("error:", error)

        return error;
      } else {
        console.log("data:", data)
        await queryClient.invalidateQueries({ queryKey: ['recipes'] })

        return data;
      }
    }
  })
}
