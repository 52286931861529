import { cn } from "@/lib/utils";
import { Star } from "lucide-react";

export enum IconSize {
  Small = "small",
  Medium = "medium",
  Large = "large",
}

export type FavoriteToggleProps = {
  isFavorite: boolean;
  onChange: (isFavorite: boolean) => void;
  size: IconSize;
};

export function FavoriteToggle({ isFavorite, onChange, size }: FavoriteToggleProps) {
  const color = "#358438";

  const iconClasses = size === IconSize.Small ? "h-4 w-4" : size === IconSize.Medium ? "h-5 w-5" : "h-6 w-6";

  return (
    <label className="inline-block">
      <input
        type="checkbox"
        style={{
          clip: "rect(0 0 0 0)",
          clipPath: "inset(50%)",
          height: "1px",
          overflow: "hidden",
          position: "absolute",
          whiteSpace: "nowrap",
          width: "1px",
        }}
        checked={isFavorite}
        onChange={(e) => onChange(e.target.checked)}
      />
      {isFavorite ? (
        <Star fill={color} stroke={color} className={cn(iconClasses)} aria-hidden="true" />
      ) : (
        <Star stroke={color} className={cn(iconClasses)} aria-hidden="true" />
      )}
    </label>
  );
}
