import axios, { AxiosHeaders, AxiosRequestHeaders } from "axios";
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { loginRequest } from '@/authConfig';
import { msalInstance } from "@/main";

export const baseUrl = import.meta.env.VITE_BACKEND_BASE_URL || "https://hacobo-api-2024-1.azurewebsites.net/api";

export const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'text/plain, application/json'
  },
});

export type GetHeaderOptions = {
  requireAccount?: boolean;
}

export const getHeadersWithAuthentication = async (headers: AxiosRequestHeaders | undefined = undefined, options: GetHeaderOptions = { requireAccount: true }) => {
  headers ??= new AxiosHeaders(axiosInstance.defaults.headers);

  if (!headers.hasContentType) {
    headers.setContentType('application/json');
  }
  if (!headers.hasAccept) {
    headers.setAccept('text/plain, application/json');
  }

  const account = msalInstance.getActiveAccount();
  if (!account) {
    if (!options.requireAccount) return headers;
    throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
  }

  const request = {
    ...loginRequest,
    account: account
  };
  try {
    const response = await msalInstance.acquireTokenSilent({
      ...request,
      redirectUri: `${window.location.origin}/blank.html`
    });

    headers.setAuthorization(`Bearer ${response.accessToken}`);
    return headers;
  } catch (ex) {
    console.error("Error acquireTokenSilent:", ex);
    if (!options.requireAccount) {
      return headers
    }

    if (ex instanceof InteractionRequiredAuthError && !options.requireAccount) {
      return headers;
    }

    await msalInstance
    .acquireTokenRedirect(request)
    .catch(function (error) {
      console.error(error);
      throw ex;
    });

    // await msalInstance
    //   .acquireTokenPopup(request)
    //   .then(function (response) {
    //     headers.setAuthorization(`Bearer ${response.accessToken}`);
    //     console.log("Token acquired from popup:", response.accessToken);
    //     return headers;
    //   })
    //   .catch(function (error) {
    //     console.error(error);
    //     throw ex;
    //   });
  }
}