import { Dayjs } from 'dayjs';

export type Category = {
  categoryId: number;
  name: string;
};

export type Recipe = {
  recipeId: number;
  name: string;
  categoryId: number;
  ingredients: string;
  procedure: string;
  source: string;
  photos?: Photo[];
  createdByUserId: number;
  createdAt: Dayjs;
};

export type Photo = {
  photoId: number;
  userId?: number;
  position: number;
  reference: string;
  contentType?: string;
};

export type RecipeDraft = {
  recipeDraftId: number;
  sourceUrl: string;
  systemPrompt?: string;
  userPrompt?: string;
  responseJson?: string;
  name?: string;
  ingredients?: string;
  procedure?: string;
  source?: string;
  metadata?: string;
  categoryId?: number;
  createdAt: Dayjs;
  createdByUserId: number;
};

export type SimpleUser = {
  userId: number;
  firstName: string;
  lastName: string;
  displayName: string;
};

export type Profile = {
  userId: number;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  roles: string[];
  favoriteRecipeIds: number[];
  recentRecipes: RecentRecipe[];
}

export type RecentRecipe = {
  recipeId: number;
  recentAt: Dayjs;
}

export const AnonymousProfile  = {
  favoriteRecipeIds: [] as number[],
  recentRecipes: [] as RecentRecipe[],
} as Profile;