import { ResponseType } from 'axios';
import { axiosInstance, getHeadersWithAuthentication } from './axiosInstance';
import dayjs from 'dayjs';
import { RecipeDraft } from './category';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const fetchRecipeDrafts = async (): Promise<RecipeDraft[]> => {
  const headers = await getHeadersWithAuthentication(undefined, { requireAccount: false });
  const response = await axiosInstance
    .get(`/recipe-drafts`, {
      headers: headers,
      responseType: 'json' as ResponseType,
    });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return response.data.map((recipeDraft: any) => {
    return {
      ...recipeDraft,
      createdAt: dayjs(recipeDraft.createdAt),
    } as RecipeDraft;
  });
};

//-- Add a recipe mutation
export const useAddRecipeDraftMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (recipeDraft: RecipeDraft) => {
      const headers = await getHeadersWithAuthentication(undefined, { requireAccount: true });
      const response = await axiosInstance.post(`/recipe-drafts`, recipeDraft, {
        headers: headers,
      })
      .catch((error) => {
        throw new Error('Failed to add recipe draft: ' + error);
      });

      return response.data as RecipeDraft;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['recipe-drafts'] })
    },
    onError: async (error) => {
      console.log("error:", error)
    }
  })
}

export const useExtractRecipeDraftMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (recipeDraftId: number) => {
      const headers = await getHeadersWithAuthentication(undefined, { requireAccount: true });
      const response = await axiosInstance.post(`/recipe-drafts/${recipeDraftId}/extract`, {}, {
        headers: headers,
      })
      .catch((error) => {
        throw new Error('Failed to extract recipe draft: ' + error);
      });

      return response.data as RecipeDraft;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['recipe-drafts'] })
    },
    onError: async (error) => {
      console.log("error:", error)
    }
  })
}
