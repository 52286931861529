import { QueryClient, queryOptions } from "@tanstack/react-query";
import { fetchCategories, fetchMe, fetchUsers } from "./categories.api";
import { fetchRecipes } from './recipes.api';
import { fetchRecipeDrafts } from './recipeDrafts.api';

export const categoriesQueryOptions = queryOptions({
  queryKey: ['categories'],
  queryFn: () => fetchCategories(),
});

export const recipesQueryOptions = queryOptions({
  queryKey: ['recipes'],
  queryFn: () => fetchRecipes(),
});

export const recipeDraftsQueryOptions = queryOptions({
  queryKey: ['recipe-drafts'],
  queryFn: () => fetchRecipeDrafts(),
});

export const usersQueryOptions = queryOptions({
  queryKey: ['users'],
  queryFn: () => fetchUsers(),
});

export const meQueryOptions = queryOptions({
  queryKey: ['me'],
  queryFn: () => fetchMe(),
});

export const recipeDataLoader = ({ queryClient }: { queryClient: QueryClient }) =>
  Promise.all([
    queryClient.ensureQueryData(recipesQueryOptions),
    queryClient.ensureQueryData(categoriesQueryOptions),
    queryClient.ensureQueryData(usersQueryOptions),
    queryClient.ensureQueryData(meQueryOptions),
  ]);