import { createFileRoute } from "@tanstack/react-router";
import { CategoryCards } from "@/components/CategoryCards";
import { RecipeTable, SortField } from "@/components/RecipeTable";
import {
  categoriesQueryOptions,
  meQueryOptions,
  recipeDataLoader,
  recipesQueryOptions,
  usersQueryOptions,
} from "@/models/categories.queryOptions";
import { useSuspenseQueries } from "@tanstack/react-query";
import { buildRecipe } from "@/models/categories.api";
import { PageTitle } from "@/components/PageTitle";

export const Route = createFileRoute("/")({
  loader: ({ context }) => recipeDataLoader(context),
  component: Index,
});

function Index() {
  const { recipes } = useSuspenseQueries({
    queries: [
      recipesQueryOptions,
      categoriesQueryOptions,
      usersQueryOptions,
      meQueryOptions,
    ],
    combine: ([recipesQuery, categoriesQuery, usersQuery, meQuery]) => {
      return {
        recipes: recipesQuery.data.map((recipe) =>
          buildRecipe(
            recipe,
            categoriesQuery.data,
            usersQuery.data,
            meQuery.data
          )
        ),
        isPending:
          recipesQuery.isPending ||
          categoriesQuery.isPending ||
          usersQuery.isPending,
      };
    },
  });

  return (
    <div className="p-2">
      <div className="md:container">
        <PageTitle>Senest tilføjede opskrifter</PageTitle>
        <div className="mt-3 mb-5">
          <RecipeTable maxCount={5} recipes={recipes || []} sortField={SortField.CreatedAt} />
        </div>
        <CategoryCards />
      </div>
    </div>
  );
}
