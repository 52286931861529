import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axiosInstance, getHeadersWithAuthentication } from "./axiosInstance";

export const useUpdateFavoriteRecipeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ recipeId, isFavorite }: { recipeId: number, isFavorite: boolean }) => {
      const headers = await getHeadersWithAuthentication(undefined, { requireAccount: true });

      console.log('mutation', recipeId, isFavorite);

      if (isFavorite) {
        const response = await axiosInstance
          .post(`/favorites`, {recipeId}, {
            headers: headers,
          })
          .catch((error) => {
            throw new Error('Failed to mark recipe as favorite: ' + error);
          });

        return response.data;
      }

      const response = await axiosInstance
        .delete(`/favorites/${recipeId}`, {
          headers: headers,
        })
        .catch((error) => {
          throw new Error('Failed to mark recipe as favorite: ' + error);
        });

      return response.data;
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ['me'] });
    },
  });

}