import { PageTitle } from "@/components/PageTitle";
import { RecipeTable } from "@/components/RecipeTable";
import { Button } from "@/components/ui/button";
import { buildRecipe } from "@/models/categories.api";
import {
  categoriesQueryOptions,
  meQueryOptions,
  recipeDataLoader,
  recipesQueryOptions,
  usersQueryOptions,
} from "@/models/categories.queryOptions";
import { useClearRecentRecipesMutation } from "@/models/recentRecipes.api";
import { useSuspenseQueries } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/recents/")({
  loader: ({ context }) => recipeDataLoader(context),
  component: RecentsPage,
});

function RecentsPage() {
  const { recipes } = useSuspenseQueries({
    queries: [
      recipesQueryOptions,
      categoriesQueryOptions,
      usersQueryOptions,
      meQueryOptions,
    ],
    combine: ([recipesQuery, categoriesQuery, usersQuery, meQuery]) => {
      return {
        recipes: recipesQuery.data
          .filter((recipe) =>
            meQuery.data.recentRecipes?.some(
              (recent) => recent.recipeId == recipe.recipeId
            )
          )
          .map((recipe) =>
            buildRecipe(
              recipe,
              categoriesQuery.data,
              usersQuery.data,
              meQuery.data
            )
          )
          .sort(
            (a, b) =>
              meQuery.data.recentRecipes.findIndex(
                (rr) => rr.recipeId == b.recipeId
              ) -
              meQuery.data.recentRecipes.findIndex(
                (rr) => rr.recipeId == a.recipeId
              )
          )
          .reverse(),
        isPending:
          recipesQuery.isPending ||
          categoriesQuery.isPending ||
          usersQuery.isPending,
      };
    },
  });

  const clearRecentRecipesM = useClearRecentRecipesMutation();

  const onClearRecentRecipes = async () => {
    await clearRecentRecipesM.mutate();
  };

  return (
    <div className="container mt-1 md:mt-3 px-2 pb-3">
      <div className="flex flex-row items-center justify-between mb-3">
        <PageTitle>Sidst sete</PageTitle>
        {recipes.length > 0 && (
          <Button
            onClick={onClearRecentRecipes}
            className="ml-3"
            variant={"outline"}
            size={"sm"}
          >
            Ryd sidst sete
          </Button>
        )}
      </div>

      {recipes.length == 0 && (
        <p>Listen er tom. Du må i gang med at se på nogle opskrifter</p>
      )}

      <RecipeTable recipes={recipes || []} showHeader={true} />
    </div>
  );
}
