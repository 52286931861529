import { Category, Recipe } from "@/models/category";
import { Table, TableBody, TableHead, TableHeader, TableRow } from "./ui/table";
import { useMemo } from "react";
import { cn } from "@/lib/utils";
import { RecipeLink } from "./RecipeLink";
import { CategoryLink } from "./CategoryLink";
import { FavoriteToggle, IconSize } from "./FavoriteToggle";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { useUpdateFavoriteRecipeMutation } from "@/models/favoriteRecipes.api";

export type RecipeWithDetails = Recipe & {
  category: Category;
  author: string;
  isFavorite: boolean;
};

type RecipeTableProps = {
  recipes: RecipeWithDetails[];
  maxCount?: number;
  showHeader?: boolean;
  showCategory?: boolean;
  sortField?: SortField;
  className?: string;
};

export const enum SortField {
  Name = "name",
  // Category = 'category',
  Author = "author",
  CreatedAt = "createdAt",
}

export const RecipeTable = ({
  recipes,
  maxCount,
  showHeader,
  showCategory,
  sortField,
  className,
}: RecipeTableProps) => {
  const latestRecipes = useMemo(() => {
    if (recipes == null) return [];

    const getSortedRecipes = (recipes: RecipeWithDetails[]) => {
      switch (sortField) {
        case SortField.Name:
          return recipes.sort((a, b) =>
            a.name.localeCompare(b.name, "da", { sensitivity: "base" })
          );
        // case SortField.Category:
        //   return recipes.sort((a, b) => a.categoryId - b.categoryId);
        case SortField.Author:
          return recipes.sort((a, b) => a.createdByUserId - b.createdByUserId);
        case SortField.CreatedAt:
          return recipes
            .sort((a, b) => a.createdAt.diff(b.createdAt))
            .reverse();
        default:
          return recipes;
      }
    };
    const updatedRecipes = getSortedRecipes(recipes).slice(0, maxCount ?? 1000);

    return updatedRecipes;
  }, [recipes, sortField, maxCount]);

  const toggleFavoriteRecipeM = useUpdateFavoriteRecipeMutation();

  const setFavorite = (recipe: RecipeWithDetails, isFavorite: boolean) => {
    toggleFavoriteRecipeM.mutate({recipeId: recipe.recipeId, isFavorite});
  };

  const tableHeadClassNames = "px-0 h-7";

  return (
    <Table className={cn(className)}>
      {showHeader && (
        <TableHeader>
          <TableRow>
            <TableHead className={cn(tableHeadClassNames)}>Name</TableHead>
            {showCategory != false && <TableHead className={cn(tableHeadClassNames)}>Kategori</TableHead>}
            <TableHead  className={cn(tableHeadClassNames, 'hidden md:table-cell')}>Oprettet Af</TableHead>
            <TableHead className={cn(tableHeadClassNames, 'hidden md:table-cell text-right')}>Oprettet</TableHead>
            <AuthenticatedTemplate>
              <TableHead className={cn(tableHeadClassNames)}></TableHead>
            </AuthenticatedTemplate>
          </TableRow>
        </TableHeader>
      )}
      <TableBody>
        {latestRecipes &&
          latestRecipes.map((recipe) => (
            <TableRow key={recipe.recipeId}>
              <td>
                <RecipeLink {...recipe} />
              </td>
              {showCategory != false && (
                <td>
                  <CategoryLink {...recipe.category} />
                </td>
              )}
              <td className={cn('hidden md:table-cell')}>{recipe.author}</td>
              <td className={cn('hidden md:table-cell text-right')}>{recipe.createdAt.fromNow()}</td>
              <AuthenticatedTemplate>
                <td className="text-right">
                  <FavoriteToggle
                    isFavorite={recipe.isFavorite}
                    onChange={(isFavorite) => setFavorite(recipe, isFavorite)}
                    size={IconSize.Small}
                  />
              </td>
              </AuthenticatedTemplate>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};
