import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { DropdownMenuItem } from "./ui/dropdown-menu";
import { Link } from "@tanstack/react-router";

export const SignInLink = () => {
  const { instance } = useMsal();

  const handleLogin = (loginType: string) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest);
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest);
    }
  };

  return (
    <>
      <DropdownMenuItem>
        <Link
          href="#"
          className="flex items-center gap-2"
          onClick={() => handleLogin("popup")}
        >
          Sign in using Popup
        </Link>
      </DropdownMenuItem>
      <DropdownMenuItem>
        <Link
          href="#"
          className="flex items-center gap-2"
          onClick={() => handleLogin("redirect")}
        >
          Sign in using Redirect
        </Link>
      </DropdownMenuItem>
    </>
  );
};
