import { PageTitle } from '@/components/PageTitle';
import { RecipeTable, SortField } from '@/components/RecipeTable';
import { buildRecipe } from '@/models/categories.api';
import { categoriesQueryOptions, meQueryOptions, recipeDataLoader, recipesQueryOptions, usersQueryOptions } from '@/models/categories.queryOptions'
import { useSuspenseQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/favorites/')({
  loader: ({ context }) => recipeDataLoader(context),
  component: FavoritesPage,
})


function FavoritesPage() {
  const { recipes } = useSuspenseQueries({
    queries: [recipesQueryOptions, categoriesQueryOptions, usersQueryOptions, meQueryOptions],
    combine: ([recipesQuery, categoriesQuery, usersQuery, meQuery]) => {
      return {
        recipes: recipesQuery.data
          .filter((recipe) => meQuery.data.favoriteRecipeIds.includes(recipe.recipeId))
          .map((recipe) => buildRecipe(recipe, categoriesQuery.data, usersQuery.data, meQuery.data)),
        isPending: recipesQuery.isPending || categoriesQuery.isPending || usersQuery.isPending,
      };
    },
  });

  return (
    <div className="container mt-1 md:mt-3 px-2 pb-3">
      <PageTitle>Favoritter</PageTitle>

      <RecipeTable recipes={recipes || []} showHeader={true} sortField={SortField.Name} />
    </div>
  )
}