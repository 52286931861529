import { LineTypes, RecipeLineType } from "@/lib/recipeFormatter";
import { Fragment } from "react/jsx-runtime";

export function RecipeLinesComponent({ lines }: { lines: RecipeLineType[] }) {
  return (
    <div className="grid grid-cols-[100px_1fr] gap-x-2 text-[15px]">
      {lines.map((line, index) => {
        switch (line.type) {
          case LineTypes.Ingredient:
            if (line.amount) {
              return (
                <Fragment key={line.index}>
                  <div className="text-right">{line.amount}</div>
                  <div>{line.product}</div>
                </Fragment>
              );
            }
            return (
              <div key={index} className="col-start-2">
                {line.product}
              </div>
            );
          case LineTypes.Bold:
            return (
              <div key={index} className="col-span-2 font-bold">
                {line.value}
              </div>
            );
          case LineTypes.Italics:
            return (
              <div key={index} className="col-span-2 italic">
                {line.value}
              </div>
            );
          case LineTypes.Text:
            return (
              <div key={index} className="col-span-2 mb-1">
                {line.value}
              </div>
            );
        }
      })}
    </div>
  );
}
