import {
  categoriesQueryOptions,
  recipesQueryOptions,
} from "@/models/categories.queryOptions";
import { useQuery } from "@tanstack/react-query";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "./ui/card";
import { cn } from "@/lib/utils";
import { Category, Recipe } from "@/models/category";
import { useMemo } from "react";
import { RecipeLink } from "./RecipeLink";
import { CategoryLink } from "./CategoryLink";

type CategoryWithRecipes = Category & {
  recipes: Recipe[];
};

export const CategoryCard = (category: CategoryWithRecipes) => {
  return (
    <Card key={category.categoryId} className={cn("w-full sm:drop-shadow-md")}>
      <CardHeader className="px-3 py-3 sm:py-1 sm:rounded-t-lg bg-apple-500 text-main-foreground">
        <CardTitle className="text-md">
          <CategoryLink {...category} />
        </CardTitle>
      </CardHeader>
      <CardContent className="hidden sm:block px-3 py-2 text-sm">
        {category.recipes?.slice(0, 3).map((recipe) => (
          <div key={recipe.recipeId}>
            <RecipeLink {...recipe} />
          </div>
        ))}
      </CardContent>
      <CardFooter className="hidden sm:block px-3 pt-1 pb-2 text-sm">
        og {category.recipes?.filter((r) => r.categoryId === category.categoryId).length} mere
      </CardFooter>
    </Card>
  );
};

export const CategoryCards = () => {
  const categoriesQuery = useQuery(categoriesQueryOptions);
  const categories = categoriesQuery.data;

  const recipesQuery = useQuery(recipesQueryOptions);
  const { data: recipes } = recipesQuery;

  const categoriesWithRecipes = useMemo(() => {
    if (categories == null || recipes == null) return [];

    const sortedRecipes = recipes
      .sort((a, b) => a.createdAt.diff(b.createdAt))
      .reverse();

    return categories
      .map((category) => ({
        ...category,
        recipes: sortedRecipes.filter(
          (r) => r.categoryId === category.categoryId
        ),
      }))
      .filter((c) => c.recipes.length > 0);
  }, [categories, recipes]);

  if (categoriesQuery.isLoading || recipesQuery.isLoading)
    return <div>Loading...</div>;

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
      {categoriesWithRecipes &&
        categoriesWithRecipes.map((category) => (
          <CategoryCard key={category.categoryId} {...category} />
        ))}
    </div>
  );
};
