import LoadingBar, { LoadingBarRef } from "react-top-loading-bar";
import { useEffect, useRef } from "react";
import { axiosInstance } from "@/models/axiosInstance";

export default function TopLoadingBar() {
  const ref = useRef<LoadingBarRef>(null);

  useEffect(() => {
    axiosInstance.interceptors.request.use(
      (config) => {
        if (ref?.current) ref.current.continuousStart();
        return config;
      },
      (error) => Promise.reject(error)
    );

    axiosInstance.interceptors.response.use(
      (response) => {
        if (ref?.current) ref.current.complete();
        return response;
      },
      (error) => {
        if (ref?.current) ref.current.complete();
        return Promise.reject(error);
      }
    );
  }, []);

  return <LoadingBar shadow color="lime" waitingTime={300}  ref={ref} />;
}