import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import { axiosInstance, getHeadersWithAuthentication } from "./axiosInstance";

export const addRecentRecipe = async (recipeId: number, queryClient: QueryClient) => {
  const headers = await getHeadersWithAuthentication(undefined, { requireAccount: true });
  const response = await axiosInstance.post(`/recents`, {recipeId}, {
    headers: headers,
  })
  .catch((error) => {
    throw new Error('Failed to add recipe to recents: ' + error);
  });

  await queryClient.invalidateQueries({ queryKey: ['me'] });

  return response.data;
}


export const useClearRecentRecipesMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async () => {
      const headers = await getHeadersWithAuthentication(undefined, { requireAccount: true });
      const response = await axiosInstance.delete(`/recents`, {
        headers: headers,
      })
      .catch((error) => {
        throw new Error('Failed to clear recents recipes: ' + error);
      });

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['me'] });
    },
  });
}
