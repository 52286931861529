import { baseUrl } from "@/models/axiosInstance";
import { Photo } from "@/models/category";
import { useState } from "react";
import { Fragment } from "react/jsx-runtime";
import Lightbox from "yet-another-react-lightbox";

type PhotoReference = {
  recipeId: number;
  photo: Photo;
};

type PhotoStripProps = {
  photos: PhotoReference[];
};

export function PhotoStrip({ photos: pr }: PhotoStripProps) {
  const [photoIndex, setPhotoIndex] = useState(-1);

  return (
    <Fragment>
      {pr.length > 0 && (
        <div className="flex flex-row flex-wrap gap-1">
          {pr.map((pr, index) => (
            <Fragment key={pr.photo.photoId}>
              <img
                src={`${baseUrl}/recipes/${pr.recipeId}/photos/${pr.photo.photoId}/thumbnail`}
                alt={pr.photo.reference}
                className="h-[60px] w-[60px] object-cover rounded-md"
                onClick={() => setPhotoIndex(index)}
              />
            </Fragment>
          ))}
          <Lightbox
            index={photoIndex}
            open={photoIndex >= 0}
            close={() => setPhotoIndex(-1)}
            slides={pr.map((photo) => ({
              src: `${baseUrl}/recipes/${photo.recipeId}/photos/${photo.photo.photoId}/original`,
              caption: photo.photo.reference,
            }))}
          />
        </div>
      )}
    </Fragment>
  );
}
