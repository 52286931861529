import { cn } from "@/lib/utils";

type PageTitleProps = {
  classNames?: string;
  children: React.ReactNode;
};

export function PageTitle(props: PageTitleProps) {
  return <h1 className={cn('text-2xl font-sans mb-3 dark:text-gray-300', props.classNames)}>{props.children}</h1>;
}
