import { PageTitle } from '@/components/PageTitle';
import { PhotoStrip } from '@/components/PhotoStrip';
import { RecipeTable, SortField } from '@/components/RecipeTable';
import { buildRecipe } from '@/models/categories.api';
import {
  categoriesQueryOptions,
  meQueryOptions,
  recipeDataLoader,
  recipesQueryOptions,
  usersQueryOptions,
} from '@/models/categories.queryOptions';
import { useSuspenseQueries } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { useMemo } from 'react';

type RecipeSearch = {
  q?: string;
}

export const Route = createFileRoute('/recipes/')({
  loader: ({ context }) => recipeDataLoader(context),

  validateSearch: (search): RecipeSearch => {
    return {q: (search.q as string | undefined)};
  },
  component: RecipesPage,
});

function RecipesPage() {
  const { q: searchFilter } = Route.useSearch();

  console.log('searchFilter', searchFilter);

  const { recipes } = useSuspenseQueries({
    queries: [
      recipesQueryOptions,
      categoriesQueryOptions,
      usersQueryOptions,
      meQueryOptions,
    ],
    combine: ([recipesQuery, categoriesQuery, usersQuery, meQuery]) => {
      return {
        recipes: recipesQuery.data.filter(r => searchFilter == null || searchFilter.length == 0 || r.name.toLowerCase().includes(searchFilter.toLowerCase())).map((recipe) =>
          buildRecipe(
            recipe,
            categoriesQuery.data,
            usersQuery.data,
            meQuery.data
          )
        ),
        isPending:
          recipesQuery.isPending ||
          categoriesQuery.isPending ||
          usersQuery.isPending,
      };
    },
  });

  const photos = useMemo(() => {
    return recipes
      ?.filter((r) => r.photos && r.photos.length > 0)
      .map((r) => ({
        recipeId: r.recipeId,
        photo: r.photos!.sort((a, b) => b.position - a.position)[0],
      }));
  }, [recipes]);

  return (
    <div className='container mt-1 md:mt-3 px-2 pb-3'>
      <PageTitle>Alle opskrifter</PageTitle>

      <div className='flex flex-col gap-y-2'>
        <div className='order-2 md:order-1'>
          <PhotoStrip photos={photos} />
        </div>

        <div className='order-1 md;order-2'>
          <RecipeTable
            recipes={recipes || []}
            showHeader={true}
            sortField={SortField.Name}
          />
        </div>
      </div>
    </div>
  );
}
