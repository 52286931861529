import {
  AuthenticationResult,
  EventMessage,
  EventType,
} from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import {
  createRootRouteWithContext,
  Link,
  Outlet,
  useLocation,
  useNavigate,
} from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { b2cPolicies } from "../authConfig";
import { Input } from "../components/ui/input";
import {
  MenuIcon,
  Moon,
  PlusIcon,
  SearchIcon,
  Sun,
  SunMoon,
  UsersIcon,
} from "lucide-react";
import { Button } from "../components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetTitle,
  SheetTrigger,
} from "../components/ui/sheet";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "../components/ui/avatar";
import { EditProfileLink } from "../components/EditProfileLink";
import { SignOutLink } from "../components/SignOutLink";
import { SignInLink } from "../components/SignInLink";
import { ResetPasswordLink } from "../components/ResetPasswordLink";
import { QueryClient } from "@tanstack/react-query";
import { useTheme } from "@/themeProvider";
import { useWakeLock } from "@/lib/useWakeLock";

export const Route = createRootRouteWithContext<{ queryClient: QueryClient }>()(
  {
    component: RootComponent,
  }
);

function RootComponent() {
  const { instance } = useMsal();

  const { theme, setTheme } = useTheme();

  useEffect(() => {
    const callbackId = instance.addEventCallback((event: EventMessage) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload
      ) {
        const payload = event.payload as AuthenticationResult;
        /**
         * For the purpose of setting an active account for UI update, we want to consider only the auth
         * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
         * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
         * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
         */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const idTokenClaims = payload.idTokenClaims as any;
        if (idTokenClaims["tfp"] === b2cPolicies.names.editProfile) {
          // retrieve the account from initial sign-in to the app
          const originalSignInAccount = instance
            .getAllAccounts()
            .find(
              (account) =>
                account.idTokenClaims!.oid === idTokenClaims.oid &&
                account.idTokenClaims!.sub === idTokenClaims.sub &&
                account.idTokenClaims!["tfp"] === b2cPolicies.names.signUpSignIn
            );

          const signUpSignInFlowRequest = {
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            account: originalSignInAccount,
          };

          // silently login again with the signUpSignIn policy
          instance.ssoSilent(signUpSignInFlowRequest);
        }
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);


  const activeAccount = instance?.getActiveAccount();
  const [emailHash, setEmailHash] = useState<string>();

  useEffect(() => {
    if (!activeAccount) return;

    generateHash(activeAccount.username.toLowerCase());

    async function generateHash(text: string) {
      const utf8 = new TextEncoder().encode(text);
      const hashedText = await crypto.subtle
        .digest("SHA-256", utf8)
        .then((hashBuffer) => {
          const hashArray = Array.from(new Uint8Array(hashBuffer));
          const hashHex = hashArray
            .map((bytes) => bytes.toString(16).padStart(2, "0"))
            .join("");
          return hashHex;
        });

      setEmailHash(hashedText);
    }
  }, [activeAccount]);

  const { isSupported, released, request, release } = useWakeLock({
    onRequest: () => console.log("Screen Wake Lock: requested!"),
    onError: () => alert("An error happened 💥"),
    onRelease: () => console.log("Screen Wake Lock: released!"),
  });

  const onThemeChange = (theme: string) => {
    switch (theme) {
      case "system":
        setTheme("system");
        break;
      case "dark":
        setTheme("dark");
        break;
      default:
        setTheme("light");
        break;
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  const [searchStr, setSearchStr] = useState<string | null>(null);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const isSearchPage = location.pathname === '/recipes';
    setSearchStr(value);
    if (value.length > 0) {
      navigate({to: `/recipes?q=${value}`, replace: location.pathname === '/recipes'});
    } else if (isSearchPage) {
      navigate({to: '/recipes', replace: false});
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const q = (location as any).search?.q;
    console.log('q', q, searchStr);
    if (q !== searchStr ) {
      setSearchStr(q);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, react-hooks/exhaustive-deps
  }, [(location as any).search?.q]);

  const buildDate = "__DATE__";

  const [isSheetOpen, setIsSheetOpen] = useState(false);

  const handleLinkClick = () => {
    console.log('handleLinkClick');
    setIsSheetOpen(false);
  };

  return (
    <>
      <header className="bg-apple-500 text-main-foreground dark:bg-apple-600 dark:text-white sticky top-0 z-50 border-b">
        <div className="mx-auto flex items-center justify-between h-11 px-2 md:px-6">
          <div className="flex gap-4">
            <Link to="/" className="flex items-center gap-2 text-lg font-bold dark:text-apple-200">
              Ha·Co·Bo
            </Link>
            {isSupported && (
              <Button
                onClick={() => {
                  released ? request() : release();
                }}
                variant={"ghost"}
                size={"sm"}
              >
                {released ? <Moon size={16} color="lightgreen" /> : <Sun size={16} color="lightgreen" />}
              </Button>
            )}
          </div>
          <div className="flex gap-4 items-center">
            <nav className="hidden md:flex items-center gap-4">
              <Link
                to="/recipes"
                className="text-sm font-medium hover:underline [&.active]:font-bold"
              >
                Alle Opskrifter
              </Link>
              <AuthenticatedTemplate>
                <Link
                  to="/favorites"
                  className="text-sm font-medium hover:underline [&.active]:font-bold"
                >
                  Favoritter
                </Link>
              </AuthenticatedTemplate>
              <Link
                to="/recents"
                className="text-sm font-medium hover:underline [&.active]:font-bold"
              >
                Sidste Set
              </Link>
              <div className="relative">
                <Input
                  type="search"
                  placeholder="Søg efter opskrift..."
                  className="pr-8 h-8 rounded-md text-black dark:text-gray-200"
                  value={searchStr || ""}
                  onChange={onSearchChange}
                />
                <SearchIcon className="absolute right-2 top-1/2 -translate-y-1/2 w-4 h-4 text-muted-foreground" />
              </div>
              <AuthenticatedTemplate>
                <Button asChild size="sm" className="h-8 bg-apple-600">
                  <Link to="/recipes/new">
                    <PlusIcon className="w-4 h-4 mr-2" />
                    Tilføj Opskrift
                  </Link>
                </Button>
              </AuthenticatedTemplate>
            </nav>

            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Avatar className="h-8 w-8">
                  <AuthenticatedTemplate>
                    {emailHash &&
                      <AvatarImage
                        src={`https://gravatar.com/avatar/${emailHash}?default=404`}
                      />
                    }
                    <AvatarFallback className="bg-apple-400">PP</AvatarFallback>
                  </AuthenticatedTemplate>
                  <UnauthenticatedTemplate>
                    <AvatarFallback className="bg-apple-400">JP</AvatarFallback>
                  </UnauthenticatedTemplate>
                  <span className="sr-only">Toggle user menu</span>
                </Avatar>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuRadioGroup
                  value={theme}
                  onValueChange={onThemeChange}
                  className="grid-flow-col"
                >
                  <DropdownMenuRadioItem value={"light"}>
                    <Sun size={16} className="mr-2" /> Lys
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value={"dark"}>
                    <Moon size={16} className="mr-2" /> Mørk
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value={"system"}>
                    <SunMoon size={16} className="mr-2" /> System
                  </DropdownMenuRadioItem>
                </DropdownMenuRadioGroup>

                <DropdownMenuSeparator />

                <AuthenticatedTemplate>
                  <DropdownMenuItem>
                    <EditProfileLink />
                  </DropdownMenuItem>

                  <DropdownMenuItem>
                    <Link href="#" className="flex items-center gap-2">
                      <UsersIcon className="h-4 w-4" />
                      <span>Personas</span>
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem>
                    <Link href="#" className="flex items-center gap-2">
                      <PlusIcon className="h-4 w-4" />
                      <span>Add Persona</span>
                    </Link>
                  </DropdownMenuItem>

                  <ResetPasswordLink />
                  <SignOutLink />
                </AuthenticatedTemplate>

                <UnauthenticatedTemplate>
                  <SignInLink />
                </UnauthenticatedTemplate>
              </DropdownMenuContent>
            </DropdownMenu>
            <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
              <SheetTrigger asChild>
                <Button variant="ghost" size="icon">
                  <MenuIcon className="w-6 h-6" />
                </Button>
              </SheetTrigger>
              <SheetContent className="p-2" side="right" aria-describedby="">
                <SheetTitle className="hidden">Menu</SheetTitle>
                <nav className="grid gap-4 p-3">
                  <Link
                    to="/recipes"
                    className="md:hidden flex items-center gap-2 text-sm font-medium"
                    onClick={handleLinkClick}
                  >
                    Alle Opskrifter
                  </Link>
                  <Link
                    to="/favorites"
                    className="md:hidden flex items-center gap-2 text-sm font-medium"
                    onClick={handleLinkClick}
                  >
                    Favoritter
                  </Link>
                  <Link
                    to="/recents"
                    className="md:hidden flex items-center gap-2 text-sm font-medium"
                    onClick={handleLinkClick}
                  >
                    Sidst sete
                  </Link>
                  <hr />
                  <Link
                    to="/settings/categories"
                    className="flex items-center gap-2 text-sm font-medium"
                    onClick={handleLinkClick}
                  >
                    Kategorier
                  </Link>
                  <AuthenticatedTemplate>
                    <Link
                      to="/recipe-drafts"
                      className="flex items-center gap-2 text-sm font-medium"
                      onClick={handleLinkClick}
                    >
                      Opskriftlinks
                    </Link>
                    <Button asChild size="sm" onClick={handleLinkClick}>
                      <Link to="/recipes/new">
                        <PlusIcon className="w-4 h-4 mr-2" />
                        Tilføj Opskrift
                      </Link>
                    </Button>
                  </AuthenticatedTemplate>
                  <div>{buildDate}</div>
                </nav>
              </SheetContent>
            </Sheet>
          </div>
        </div>
      </header>

      <Outlet />

      {/* <TanStackRouterDevtools /> */}
    </>
  );
}
